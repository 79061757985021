import React, { useEffect, useState } from "react";
import './../Stripe.css';
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

export default function AddNewPayment(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [return_url, setReturnUrl] = useState('https://waterbill.lincoln.ne.gov/statement-summary');

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = props.clientSecret;

    if (!clientSecret) {
      return;
    }

    if (props.manageAccount) {
      setReturnUrl('https://waterbill.lincoln.ne.gov/account');
    }

    stripe.retrieveSetupIntent(clientSecret);
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    let { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: return_url
      }
    })

    // if (error && error.code === 'parameter_missing' || error.code === 'setup_intent_unexpected_state') { //Collect bank info
    //   error = null;
    //   await stripe.collectUsBankAccountForSetup(props.clientSecret, {
    //     billing_details: {
    //       name: 'nate bobbyman',
    //       email: 'ncarlson@beehiveindustries.com'
    //     }
    //   })
    //     .then(({ payIntent, error }) => {
    //       if (error) {
    //         console.log(error);
    //       } else {
    //         stripe.confirmUsBankAccountSetup(props.clientSecret).then(({ setupIntent, error }) => {
    //           if (error) {
    //             setMessage(error.message);
    //           } else {
    //             if (setupIntent.status === 'succeeded' || setupIntent.status === 'processing') {
    //               window.location.reload();
    //             } else {
    //               setMessage('You will be sent an email to confirm the microdeposits with a link to complete your payment. Expect a $0.01 deposit to the account provided in 1–2 business days and an email with additional instructions to verify your bank account.')
    //             }
    //           }
    //         })
    //       }
    //     });
    // }

    if (error && (error.type === "card_error" || error.type === "validation_error")) {
      setMessage(error.message);
    } else if (error) {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Add Payment Method"}
        </span>
      </button>
      {message && <div className='errors-stripe' id="payment-message">{message}</div>}
    </form>
  );
}