import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import CheckoutForm from './CheckoutForm';
import { Elements } from "@stripe/react-stripe-js";
import { queryHelpers } from "@testing-library/dom";
import * as helpers from './../helpers';


export function QuickPaySubmitted(props) {

  const { instance, accounts, inProgress } = useMsal();

  let authenticate = () => {
    instance.loginRedirect();
  }

  let params = new URLSearchParams(window.location.search);
  let amount = params.get('amount');

  return (
    <div className='flex'>
      <div className='quick-pay-pic' />
      <div className='quick-content'>
        <img className='city-logo' src='https://beehivetilcache.blob.core.windows.net/staticassets/B2C/NE_Lincoln/Logo.svg' />
        {!props.failed &&
          <div className='quick-pay-form'>
            <div className='flex-row'>
              <svg className='margin-right' xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 48 48" version="1" enable-background="new 0 0 48 48">
                <polygon fill="#43A047" points="40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9" />
              </svg>
              <h2>Payment Submitted Successfully!</h2>
            </div>
            <div>
              <p>
                <span className='bold-txt'>Payment Amount: </span>{helpers.formatToCurrency(props.amount || amount)}<br />
              </p>
              <p>It may take up to 20 minutes for your payment to process.</p>
              <p>You have been sent a receipt to the email address provided.</p>
              <Link to="quickpay"><input className="blue-button max-width" type="button" value="Make Another Payment" /></Link>
            </div>
          </div>}
        {props.failed &&
          <div className='quick-pay-form'>
            <div className='flex-row'>
              <svg className='margin-right' xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" role="img" aria-labelledby="dangerIconTitle" stroke="#000000" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" fill="none" color="#000000"> <title id="dangerIconTitle">Danger</title> <path d="M12 10L12 13" /> <line x1="12" y1="16" x2="12" y2="16" /> <path d="M12.8619342,4.46528817 L21.1135176,18.4929799 C21.3935371,18.969013 21.2346366,19.5819147 20.7586035,19.8619342 C20.6049268,19.9523322 20.4298761,20 20.2515834,20 L3.74841664,20 C3.19613189,20 2.74841664,19.5522847 2.74841664,19 C2.74841664,18.8217072 2.7960844,18.6466565 2.88648243,18.4929799 L11.1380658,4.46528817 C11.4180853,3.98925504 12.030987,3.83035459 12.5070201,4.11037408 C12.6535738,4.19658212 12.7757262,4.3187345 12.8619342,4.46528817 Z" />
              </svg>
              <h2>Payment Failed!</h2>
            </div>
            <div>
              <p className='errors'>
                There was an error in taking your payment. <br /><br />
                Please try again.
              </p>
              <Link to="quickpay"><input className="blue-button max-width" type="button" value="Make Another Payment" /></Link>
            </div>
          </div>}
        <div className='center pad-bot'>
          Would you like to login or sign up to manage Autopay? <span onClick={authenticate} className='blue-text'>Click Here</span>
        </div>
      </div>
    </div>
  );
}

export default QuickPaySubmitted;