
import React, { useState } from 'react';
import ReactDOM from "react-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import App from "./App.js";
import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <App msalInstance={msalInstance} />
  </React.StrictMode>,
  document.getElementById("root")
);