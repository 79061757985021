import { useMsal } from '@azure/msal-react';
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export function Navigation(props) {
  const [droppedDown, setDropDown] = useState(false);
  const { instance, accounts, inProgress } = useMsal();
  const [isMobile, setIsMobile] = useState(false);

  let handleWindowSizeChange = () => {
    if (window.innerWidth <= 800) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  let menuDropdown = () => {
    setDropDown(!droppedDown);
  }

  let hideMenu = () => {
    setDropDown(false);
  }

  let logout = () => {
    let curAct = instance.getAccountByHomeId(accounts[0].homeAccountId);
    instance.logoutRedirect({ account: curAct });
  }

  let accountChange = (e) => {
    let selectedId = e.target.value;
    let selectedAcc = props.bettyUser.user.Accounts.find(x => x.AcctNumber == selectedId);
    props.setActiveAccount(selectedAcc);
  }

  return (
    <div>
      <div className="navbar">
        <div className='smol-nav' onClick={menuDropdown}>
          <svg focusable="false" viewBox="0 0 24 24" >
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z">
            </path>
          </svg>
        </div>
        <div className='big-nav'>
          <Link to="statement-summary">Statement Summary</Link>
          <Link to="account">Manage Account</Link>
          <Link to="bill-history">Bill History</Link>
          <Link to="help">Help</Link>
        </div>
        <div className='flex-acc-num'>
          <span className='account-number-text'>Account Number:</span>
          <select className='account-number-select' onChange={(e) => accountChange(e)}>
            {
              props.bettyUser && props.bettyUser.user && props.bettyUser.user.Accounts ? props.bettyUser.user.Accounts.map(x => {
                return <option selected={x.AcctNumber == props.activeAccount.AcctNumber} value={x.AcctNumber} key={x.AcctNumber}>{x.AcctNumber} {!isMobile ? '/ ' + x.Address : ''}</option>
              }) : <option key='1'></option>
            }
          </select>
        </div>
        <div>
          <a onClick={logout}>Log out</a>
        </div>
      </div>
      <div className={'mobile-nav-links ' + (!droppedDown ? 'hidden' : '')}>
        <div className='mobile-nav-link'><Link onClick={hideMenu} to="statement-summary">Statement Summary</Link></div>
        <div className='mobile-nav-link'><Link onClick={hideMenu} to="account">Manage Account</Link></div>
        <div className='mobile-nav-link'><Link onClick={hideMenu} to="bill-history">Bill History</Link></div>
        <div className='mobile-nav-link'><Link onClick={hideMenu} to="help">Help</Link></div>
      </div>
    </div>
  );
}

export default Navigation;