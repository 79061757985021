import React, { useState, useEffect } from "react";

import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";

import { msalConfig, b2cPolicies } from "./authConfig";
import AppLanding from './AppLanding';
import { isMobileOnly, isMobile, isTablet } from 'react-device-detect';

if (isMobileOnly) {
  var meta = document.createElement('meta');
  meta.name = "viewport";
  meta.content = "width=400, maximum-scale=1.0, user-scalable=no";
  document.getElementsByTagName('head')[0].appendChild(meta);
  document.body.classList.add('mobile-app');
  window.IsMobile = true;
}
// import { PageLayout, IdTokenClaims } from "./ui.jsx";


// const IdTokenContent = () => {
//   /**
//    * useMsal is hook that returns the PublicClientApplication instance, 
//    * an array of all accounts currently signed in and an inProgress value 
//    * that tells you what msal is currently doing. For more, visit:
//    * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
//    */
//   const { accounts } = useMsal();
//   const [idTokenClaims, setIdTokenClaims] = useState(null);

//   function GetIdTokenClaims() {
//     setIdTokenClaims(accounts[0].idTokenClaims)
//   }

//   return (
//     <>
//       <h5 className="card-title">Welcome {accounts[0].name}</h5>
//       {idTokenClaims ?
//         <IdTokenClaims idTokenClaims={idTokenClaims} />
//         :
//         <div onClick={GetIdTokenClaims}>View ID Token Claims</div>
//       }
//     </>
//   );
// };

/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
 * only render their children if a user is authenticated or unauthenticated, respectively.
 */
const MainContent = () => {

  const { instance } = useMsal();

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted. 
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
          } else if (event.interactionType === InteractionType.Popup) {
            instance.loginPopup(b2cPolicies.authorities.forgotPassword)
              .catch(e => {
                return;
              });
          }
        }
      }

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        if (event?.payload) {
          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (event.payload.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword) {
            window.alert("Password has been reset successfully. \nPlease sign-in with your new password");
            return instance.logout();
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  let initialPage = !!sessionStorage['msal.idtoken'] ? 'statement-summary' : 'quickpay';
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <AppLanding loggedIn={true} />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <AppLanding loggedIn={false} />
      </UnauthenticatedTemplate>
    </div>
  );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be 
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more,
 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export default function App({ msalInstance }) {

  return (
    <MsalProvider instance={msalInstance}>
      <MainContent />
    </MsalProvider>
  );
}