import React, { useState, useEffect } from "react";
import Navigation from './Navigation';
import * as helpers from './../helpers';
import { Elements } from "@stripe/react-stripe-js";
import AddNewPayment from './AddNewPayment';
import { useHistory } from "react-router-dom";

export function StatementSummary(props) {

  const history = useHistory();

  const [clientSecret, setClientSecret] = useState('');
  const [paymentRadio, setPaymentRadio] = useState('');
  const [customAmount, setCustomAmount] = useState(0);
  const [twoForTrees, setTwoForTrees] = useState(false);
  const [errors, setErrors] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  // const [currentDue, setCurrentDue] = useState(0);
  // const [pastDue, setPastDue] = useState(0);
  // const [defaultPaymentMethod, setDefaultPaymentMethod] = useState('');

  const [paymentMethodRadio, setPaymentMethodRadio] = useState('card');

  let addPaymentMethod = () => {
    let modal = document.getElementById('add-payment-modal');
    modal.style.display = "block";
    setErrors('');
    stripeSetupIntent(props.customerId);
  }

  let payBill = () => {
    setIsLoading(true);
    let payment = 0;
    let errors = '';
    let paymentMethod = '';

    switch (paymentRadio) {
      case 'current':
        payment += props.activeAccount.CurrentBalance;
        break;
      case 'past':
        payment += props.activeAccount.PastDue;
        break;
      case 'other':
        if (customAmount > 0) {
          payment += customAmount;
        } else {
          payment = 0;
        }
        break;
      default:
        errors += 'ERROR: Must select a payment amount. ';
        break;
    }

    if (twoForTrees) {
      payment += 2;
    }

    if (payment < .50 && !errors) {
      errors += 'ERROR: Must make a payment for more than $0.50. ';
    }

    payment = payment.toFixed(2);

    if (paymentMethodRadio == 'card') {
      let x = document.getElementById('card-select');
      paymentMethod = x.value;
    } else {
      let x = document.getElementById('bank-select');
      paymentMethod = x.value;
    }

    if (!paymentMethod) {
      errors += 'ERROR: You must select a method to pay with. ';
    }

    let errorBox = document.getElementById('error-box');

    if (errors) {
      errorBox.style.display = "flex";
      setErrors(errors);
      setIsLoading(false);
    } else {
      errorBox.style.display = "none";
      setErrors('');
      helpers.apiAuth('PortalPayNow', { customerId: props.customerId, amount: payment, paymentMethod: paymentMethod, email: props.bettyUser.user.EMail, accountNumber: props.activeAccount.AcctNumber, twoForTrees: twoForTrees }, (result) => {
        setIsLoading(false);
        if (result.payIntent && result.payIntent.status === 'succeeded') {
          history.push('/statement-summary-complete?success=yes&amount=' + payment);
        } else if (result.payIntent && result.payIntent.status === 'processing') {
          history.push('/statement-summary-complete?success=processing&amount=' + payment);
        } else {
          history.push('/statement-summary-complete?success=no');
        }
      })
    }
  }

  //setupintent
  let stripeSetupIntent = (customerId) => {
    helpers.apiAuth('SetupIntent', { customerId: customerId }, (result) => {
      setClientSecret(result.clientSecret);
    });
  };

  let closeModal = () => {
    let modal = document.getElementById('add-payment-modal');
    if (modal) {
      modal.style.display = "none";
    }
  }

  let keyPress = (e) => {
    if (e.key === 'Escape') {
      closeModal();
    }
  }

  document.addEventListener('keydown', keyPress, { once: true });

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
    loader: 'auto'
  };

  return (
    <div>
      <Navigation bettyUser={props.bettyUser} activeAccount={props.activeAccount} setActiveAccount={props.setActiveAccount} />
      <div className='page-content'>
        {!props.bettyUser.user || !props.stripeUser || isLoading ?
          <div id='loading-modal' className='modal'>
            <div className='loader'><div className='loader-white'></div><div className='loader-light'></div><div className='loader-ovl'></div></div>
          </div>
          : <div>
            <div id='add-payment-modal' className='modal'>
              <div className='modal-content'>
                <span className="close" id="close-modal" onClick={closeModal}>&times;</span>
                <div className='modal-form'>
                  <h2>Add a New Payment Method</h2><br />
                  {clientSecret ? <div>
                    <Elements options={options} stripe={props.stripePromise} className='stripe'>
                      <AddNewPayment clientSecret={clientSecret} />
                    </Elements>
                  </div> : <div id='loading-modal' className='modal'>
                    <div className='loader'><div className='loader-white'></div><div className='loader-light'></div><div className='loader-ovl'></div></div>
                  </div>}
                </div>
              </div>
            </div>
            <div>
              <h2>Statement Summary</h2>
              <p>
                {props.activeAccount.Address}<br />
                Account #: {props.activeAccount.AcctNumber}
              </p>
              <p>
                Current Balance: <br />
                <span className='bold-txt'>{helpers.formatToCurrency(props.activeAccount.CurrentBalance * 100)}</span>
              </p>
              <p>
                Due Date: <br />
                <span className='bold-txt'>{props.activeAccount.DueDate}</span>
              </p>
            </div>

            <div><hr />
              <h2>Pay Bill</h2><br />
              <div className='payment-account'>
                <p><span className='bold-txt'>Select your payment amount</span></p>
                <div className="">
                  <label>
                    <input onChange={(e) => { setPaymentRadio(e.target.value) }} checked={paymentRadio === 'current'} type="radio" value="current" name="amount" /> <span className='bold-txt'>{helpers.formatToCurrency(props.activeAccount.CurrentBalance * 100)}</span> (current amount due)<br />
                  </label>
                  <label>
                    <input onChange={(e) => { setPaymentRadio(e.target.value) }} checked={paymentRadio === 'past'} type="radio" value="past" name="amount" /> <span className='bold-txt'>{helpers.formatToCurrency(props.activeAccount.PastDue * 100)}</span> (past due amount)<br />
                  </label>
                  <div className='otherAmountEntry'>
                    <label>
                      <input onChange={(e) => { setPaymentRadio(e.target.value); document.getElementById('customAmount').focus(); }} checked={paymentRadio === 'other'} type="radio" value="other" name="amount" /> Other Amount <br />
                    </label>
                    <label>
                      <input onChange={(e) => { setCustomAmount(parseFloat(e.target.value)) }} id="customAmount" type="number" min='0' placeholder='0.00' /><br />
                    </label>
                  </div>
                  <label>
                    <input onChange={(e) => { setTwoForTrees(!twoForTrees) }} type="checkbox" /> Include $2 towards 2 for Trees<hr />
                  </label>
                </div>
              </div>
              <p><span className='bold-txt'>Select your payment method</span></p>
              <div className="radio-container">
                <label>
                  <input checked={paymentMethodRadio === 'card'} onChange={(e) => { setPaymentMethodRadio(e.target.value) }} type="radio" value="card" name="payment-method" /> Debit/Credit Card <br />
                </label>
                <label>
                  <input checked={paymentMethodRadio === 'bank'} onChange={(e) => { setPaymentMethodRadio(e.target.value) }} type="radio" value="bank" name="payment-method" /> Bank Transfer <br />
                </label>
              </div>
            </div>

            <div className='payment-details'>
              <p><span className='bold-txt'>Enter your payment details</span></p>
              {paymentMethodRadio === 'card' && <div>
                <p className='bold-small'>Select Saved Credit Card</p>
                <select id='card-select'>
                  {props.stripeUser.paymentMethods && props.stripeUser.paymentMethods.data ? props.stripeUser.paymentMethods.data.map(x => {
                    return <option selected={props.stripeUser.customer.invoice_settings.default_payment_method == x.id} key={x.id} value={x.id} >{x.card.brand.toUpperCase()} - {x.card.last4}</option>
                  }) : 'no'}
                </select>
              </div>}
              {paymentMethodRadio === 'bank' && <div>
                <p className='bold-small'>Select Saved Bank Info</p>
                <select id='bank-select'>
                  {props.stripeUser.bankAccountsPM && props.stripeUser.bankAccountsPM.data ? props.stripeUser.bankAccountsPM.data.map(x => {
                    return <option selected={props.stripeUser.customer.invoice_settings.default_payment_method == x.id} key={x.id} value={x.id} >{x.us_bank_account.bank_name} - {x.us_bank_account.last4}</option>
                  }) : 'no'}
                </select>
              </div>}
              <p className='blue-text' onClick={addPaymentMethod}>+ Add Payment Method</p>
            </div>
            <div id='error-box'>
              <svg className='error-svg' xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" role="img" aria-labelledby="dangerIconTitle" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" color="#000000"> <title id="dangerIconTitle">Danger</title> <path d="M12 10L12 13" /> <line x1="12" y1="16" x2="12" y2="16" /> <path d="M12.8619342,4.46528817 L21.1135176,18.4929799 C21.3935371,18.969013 21.2346366,19.5819147 20.7586035,19.8619342 C20.6049268,19.9523322 20.4298761,20 20.2515834,20 L3.74841664,20 C3.19613189,20 2.74841664,19.5522847 2.74841664,19 C2.74841664,18.8217072 2.7960844,18.6466565 2.88648243,18.4929799 L11.1380658,4.46528817 C11.4180853,3.98925504 12.030987,3.83035459 12.5070201,4.11037408 C12.6535738,4.19658212 12.7757262,4.3187345 12.8619342,4.46528817 Z" /></svg>
              {errors && <p>{errors}</p>}
            </div>
            {props.activeAccount.AcctNumber && <input className="blue-button" type="button" value="Pay Bill" onClick={payBill} />}
          </div>
        }
      </div>
    </div>
  );
}

export default StatementSummary;