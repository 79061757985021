

const baseUri = 'https://paymentportal.azure-api.net/'
// const baseUri = 'http://localhost:7071/api/'

export const addressCheckApi = (endpoint, body, callback) => {
  fetch('https://public311.azure-api.net/Public311Api/' + endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => {
      callback(data);
    });
}

export const api = (endpoint, body, callback) => {
  fetch(baseUri + endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => {
      callback(data);
    });
}

export const apiAuth = (endpoint, body, callback) => {
  window.getKey((key) => {
    fetch(baseUri + endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + key,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        callback(data);
      });
  });
}

export const formatToCurrency = amount => {
  return "$" + (amount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}
