import React, { useState, useEffect } from "react";
import Navigation from './Navigation';
import * as helpers from './../helpers';

export function BillHistory(props) {

  const [billHistory, setBillHistory] = useState('');
  const [modalMeters, setModalMeters] = useState('');
  const [meterDateActive, setMeterDateActive] = useState('');

  useEffect(() => {
    if (billHistory.AcctId != props.activeAccount.AcctId) {
      let activeAcc = props.bettyUser.user.Accounts.find(x => x.AcctId == props.activeAccount.AcctId);
      setBillHistory({ History: activeAcc.BillHistory, AcctId: props.activeAccount.AcctId });
    }
  })

  let closeModal = () => {
    let modal = document.getElementById('meter-read-modal');
    if (modal) {
      modal.style.display = "none";
    }
  }

  let meterModal = (x) => {
    setMeterDateActive(x.DueDate);
    setModalMeters(x.MeterReads);
    let modal = document.getElementById('meter-read-modal');
    modal.style.display = "block";
  }

  let keyPress = (e) => {
    if (e.key === 'Escape') {
      closeModal();
    }
  }

  document.addEventListener('keydown', keyPress);

  // const fakeReads = [
  //   {
  //     "ReadId": 5816526,
  //     "SerialId": "700018320",
  //     "MeterStart": 9111,
  //     "MeterEnd": 9111,
  //     "Diameter": "4",
  //     "ReadDate": "2022-01-06"
  //   },
  //   {
  //     "ReadId": 5153717,
  //     "SerialId": "700018321",
  //     "MeterStart": 2468,
  //     "MeterEnd": 2468,
  //     "Diameter": "4",
  //     "ReadDate": "2022-01-06"
  //   },
  //   {
  //     "ReadId": 5830561,
  //     "SerialId": "949446661",
  //     "MeterStart": 1,
  //     "MeterEnd": 1,
  //     "Diameter": "1 1\/2",
  //     "ReadDate": "2022-01-06"
  //   }
  // ];

  return (
    <div>
      <Navigation bettyUser={props.bettyUser} activeAccount={props.activeAccount} setActiveAccount={props.setActiveAccount} />
      <div className="page-content-bills">
        <div id='meter-read-modal' className='modal'>
          <div className='modal-content'>
            <span className="close" id="close-modal" onClick={closeModal}>&times;</span>
            <div className='modal-form'>
              <h2 className='meter-reading-header'>Meter Readings for {meterDateActive}</h2><hr />
              {modalMeters && modalMeters.map(x => {
                return <table className='meter-read-table'>
                  <tr className='table-header'>
                    <td>Serial Id</td>
                    <td className='bold-txt'>{x.SerialId}</td>
                  </tr>
                  <tr className='marg-bot'>
                    <td>Meter Start</td>
                    <td className='bold-txt'>{x.MeterStart}</td>
                  </tr>
                  <tr className='marg-bot'>
                    <td>Meter End</td>
                    <td className='bold-txt'>{x.MeterEnd}</td>
                  </tr>
                  <tr>
                    <td>Date Read</td>
                    <td className='bold-txt'>{x.ReadDate}</td>
                  </tr>
                  <tr>
                    <td>Meter Diameter</td>
                    <td className='bold-txt'>{x.Diameter}</td>
                  </tr>
                </table>
              })}
            </div>
          </div>
        </div>
        <h3>Bill History for {props.activeAccount.AcctNumber}</h3><hr />
        <div className='bill-grids'>
          {billHistory && billHistory.History && billHistory.History.map(x => {
            return <table className='meter-read-table bill-grid'>
              <tr className='table-header'>
                <th>{x.DueDate}</th>
                <th></th>
              </tr>
              {x.MeterReads && x.MeterReads.length > 0 ?
                <tr>
                  <td colSpan={2}><div onClick={() => meterModal(x)} className='meter-read-btn'>View Meter Readings</div></td>
                </tr>
                :
                <tr>
                  <td colSpan={2}><div className='meter-read-btn-dead'>View Meter Readings</div></td>
                </tr>
              }
              <tr><td className='table-header'></td><td className='table-header'></td></tr>
              <tr className='marg-bot'>
                <td>Water Usage</td>
                <td className='bold-txt'>{x.WaterUsage}</td>
              </tr>
              <tr><td className='table-header'></td><td className='table-header'></td></tr>
              <tr>
                <td>Water Fee</td>
                <td className='bold-txt'>{helpers.formatToCurrency(x.WaterFee * 100)}</td>
              </tr>
              <tr>
                <td>Service Charge</td>
                <td className='bold-txt'>{helpers.formatToCurrency(x.WaterService * 100)}</td>
              </tr>
              <tr>
                <td>Sales Tax</td>
                <td className='bold-txt'>{helpers.formatToCurrency(x.WaterTax * 100)}</td>
              </tr>
              <tr><td className='table-header'></td><td className='table-header'></td></tr>
              <tr>
                <td>Wastewater Fee</td>
                <td className='bold-txt'>{helpers.formatToCurrency(x.WWFee * 100)}</td>
              </tr>
              <tr>
                <td>Service Charge</td>
                <td className='bold-txt'>{helpers.formatToCurrency(x.WWService * 100)}</td>
              </tr>
              <tr>
                <td>Sales Tax</td>
                <td className='bold-txt'>{helpers.formatToCurrency(x.WWTax * 100)}</td>
              </tr>
              <tr><td className='table-header'></td><td className='table-header'></td></tr>
              <tr>
                <td>Total Amount</td>
                <td className='bold-txt'>{helpers.formatToCurrency(x.Amount * 100)}</td>
              </tr>
            </table>
          })}
        </div>
      </div>
    </div >
  );
}

export default BillHistory;