import React, { useState, useEffect } from "react";
import Navigation from './Navigation';
import * as helpers from './../helpers';
import { Elements } from "@stripe/react-stripe-js";
import AddNewPayment from './AddNewPayment';
import InputMask from 'react-input-mask';

export function ManageAccount(props) {

  const [clientSecret, setClientSecret] = useState('');

  const [formProperties, setFormProperties] = useState({ ...props.bettyUser.user, ...props.activeAccount });
  const [activeAccountId, setActiveAccountId] = useState(props.activeAccount.AcctId);

  const [changedProps, setChangedProps] = useState({});
  const [errors, setErrors] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  let [PMDeletionElement, setPMDeletionElement] = useState(<></>);
  let [PMDeletionId, setPMDeletionId] = useState('');

  useEffect(() => {
    if (activeAccountId != props.activeAccount.AcctId) {
      setChangedProps({});
      setActiveAccountId(props.activeAccount.AcctId);
      setFormProperties({ ...props.bettyUser.user, ...props.activeAccount });
    }
  })

  let onFormChange = (propName, propValue) => {
    if (propName === 'autopay') {
      if (propValue == true && !props.stripeUser.customer.invoice_settings.default_payment_method) {
        setErrors('ERROR: You must set a default payment method before turning on autopay');
        return;
      } else {
        setErrors('');
      }
    }
    if (propName === 'Phone1') {
      propValue = propValue.replace(/[^0-9]+/g, "");
    }
    if (propName === 'contactByEMail' || propName === 'twoForTrees' || propName === 'autopay' || propName === 'citySendUpdates') {
      setChangedProps({ ...changedProps, [propName]: propValue });
    } else {
      setChangedProps({ ...changedProps, [propName]: propValue.toUpperCase() });
    }
    setFormProperties({ ...formProperties, [propName]: propValue });
  }

  let deletePaymentMethod = (paymentMethodId) => {
    closeDeletionModal();
    let loadModal = document.getElementById('loading-stripe-modal');
    if (loadModal) {
      loadModal.style.display = 'flex';
    }
    helpers.apiAuth('DeletePaymentMethod', { paymentMethodId: paymentMethodId }, (r) => {
      helpers.apiAuth('GetStripeCustomerData', { customerId: props.customerId }, (result) => {
        props.setStripeUser({ customer: result.customer, paymentMethods: result.paymentMethods, bankAccountsPM: result.bankAccountsPM });
        if (loadModal) {
          loadModal.style.display = 'none';
        }
      });
    })
  }

  let showDeletionModal = (paymentMethod) => {
    let modal = document.getElementById('deletion-modal');
    if (modal) {
      modal.style.display = 'block';
    }
    let output = <p></p>;
    if (paymentMethod.card) {
      output = <p>Are you sure you want to delete <b>{paymentMethod.card.brand.toUpperCase()}</b> ending in <b>{paymentMethod.card.last4}</b>?</p>;
    } else {
      output = <p>Are you sure you want to delete <b>{paymentMethod.us_bank_account.bank_name}</b> ending in <b>{paymentMethod.us_bank_account.last4}</b>?</p>;
    }
    setPMDeletionElement(output);
    setPMDeletionId(paymentMethod.id);
  }

  let setDefaultPaymentMethod = (paymentMethodId) => {
    let loadModal = document.getElementById('loading-stripe-modal');
    if (loadModal) {
      loadModal.style.display = 'flex';
    }
    helpers.apiAuth('SetDefaultPaymentMethod', { paymentMethodId: paymentMethodId, customerId: props.customerId }, (r) => {
      helpers.apiAuth('GetStripeCustomerData', { customerId: props.customerId }, (result) => {
        props.setStripeUser({ customer: result.customer, paymentMethods: result.paymentMethods, bankAccountsPM: result.bankAccountsPM });
        if (loadModal) {
          loadModal.style.display = 'none';
        }
      });
    })
  }

  let closeDeletionModal = () => {
    let modal = document.getElementById('deletion-modal');
    if (modal) {
      modal.style.display = "none";
    }
  }

  let closeModal = () => {
    let modal = document.getElementById('add-payment-modal');
    if (modal) {
      modal.style.display = "none";
    }
  }

  let keyPress = (e) => {
    if (e.key === 'Escape') {
      closeModal();
    }
  }

  document.addEventListener('keydown', keyPress);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
    loader: 'auto'
  };

  let addPaymentMethod = () => {
    let modal = document.getElementById('add-payment-modal');
    modal.style.display = "block";
    stripeSetupIntent(props.customerId);
  }

  let stripeSetupIntent = (customerId) => {
    helpers.apiAuth('SetupIntent', { customerId: customerId }, (result) => {
      setClientSecret(result.clientSecret);
    });
  };

  async function saveAccountChanges() {
    setIsLoading(true);
    let errors = '';
    if (changedProps.Address1 === '') {
      errors += 'Address is a required field. \n';
    }
    if (changedProps.City === '') {
      errors += 'City is a required field. \n';
    }
    if (changedProps.State === '') {
      errors += 'State is a required field. \n';
    }
    if (changedProps.Zip === '') {
      errors += 'Zip is a required field. \n';
    }

    if (changedProps.Address1 || changedProps.Zip) {
      await helpers.addressCheckApi('ValidateAddress', { address1: formProperties.Address1, address2: formProperties.Address2, city: formProperties.City, state: formProperties.State, zip: formProperties.Zip }, (result) => {
        console.log(result);
        result = JSON.parse(result);
        if (result.resultStatus === 'SUCCESS') {
          let returnedAddress = result.addressList[0];
          changedProps.Address1 = returnedAddress.addressLine1;
          changedProps.Zip = returnedAddress.zip5 + returnedAddress.zip4;
          checkErrorsMakeCall(errors);
        } else {
          errors += 'Address not found. Please Enter a valid address. \n';
          checkErrorsMakeCall(errors);
        }
      })
    } else {
      checkErrorsMakeCall(errors);
    }

    // checkErrorsMakeCall(errors);

  }

  let checkErrorsMakeCall = (errors) => {
    if (errors.length > 0 || Object.keys(changedProps).length === 0) {
      setIsLoading(false);
      let element = document.getElementById('saveBtn');
      if (element) {
        element.scrollIntoView();
      }
      setErrors(errors);
    } else {
      setErrors('');
      helpers.apiAuth('UpdateCustomerInfo', { changedProps: changedProps, customerId: props.customerId, acctId: props.activeAccount.AcctId }, (result) => {
        helpers.apiAuth('GetAccount', {}, (result) => {
          setIsLoading(false);
          setChangedProps({});
          props.setBettyUser({ user: result });
          result.Accounts.forEach(x => {
            if (x.AcctId === props.activeAccount.AcctId) {
              props.setActiveAccount(x);
              setFormProperties({ ...result, x });
            }
          })
        });
      });
    }
  }

  return (
    <div>
      <Navigation bettyUser={props.bettyUser} activeAccount={props.activeAccount} setActiveAccount={props.setActiveAccount} />
      <div className='page-content'>
        <div id='loading-stripe-modal' className='modal'>
          <div className='loader'><div className='loader-white'></div><div className='loader-light'></div><div className='loader-ovl'></div></div>
        </div>
        <div id='deletion-modal' className='modal'>
          <div className='modal-content'>
            <span className="close" id="close-modal" onClick={closeDeletionModal}>&times;</span>
            <div className='modal-form'>
              <h3>Delete Payment Method</h3>
              {PMDeletionElement}<br />
              <div className='yes-no-div'>
                <div><input onClick={() => deletePaymentMethod(PMDeletionId)} className='yes-btn' type='button' value='Yes' /></div>
                <div><input onClick={closeDeletionModal} className='no-btn' type='button' value='No' /></div>
              </div>
            </div>
          </div>
        </div>
        {!props.bettyUser.user || isLoading ?
          <div id='loading-modal' className='modal'>
            <div className='loader'><div className='loader-white'></div><div className='loader-light'></div><div className='loader-ovl'></div></div>
          </div>
          : <div className='manage-acc-content'>
            <div id='add-payment-modal' className='modal'>
              <div className='modal-content'>
                <span className="close" id="close-modal" onClick={closeModal}>&times;</span>
                <div className='modal-form'>
                  <h2>Add a New Payment Method</h2><br />
                  {clientSecret ? <div>
                    <Elements options={options} stripe={props.stripePromise} className='stripe'>
                      <AddNewPayment clientSecret={clientSecret} manageAccount={true} />
                    </Elements>
                  </div> : <div id='loading-modal' className='modal'>
                    <div className='loader'><div className='loader-white'></div><div className='loader-light'></div><div className='loader-ovl'></div></div>
                  </div>}
                </div>
              </div>
            </div>

            <div>
              <h2>Manage Account</h2>
            </div>

            <div>
              <p><span className='bold-txt'>What is your preferred communication method?</span></p>
              <div className="radio-container">
                <label>
                  <input type="radio" value="paper" name="communication-method" checked={!formProperties.contactByEMail} onChange={(e) => onFormChange('contactByEMail', false)} /> Paper Bills (sent by mail) <br />
                </label>
                <label>
                  <input type="radio" value="email" name="communication-method" checked={formProperties.contactByEMail} onChange={(e) => onFormChange('contactByEMail', true)} /> Email <br />
                </label>
              </div>
            </div>

            <div className='billing-info'>
              <p><span className='bold-txt'>Billing Information</span></p>
              <label>
                Address 1 <span className='required'>*</span><br />
                <input type="text" id="address1" value={formProperties.Address1} onChange={(e) => onFormChange('Address1', e.target.value)} /> <br />
              </label>
              <label>
                Address 2 <br />
                <input type="text" id="address2" value={formProperties.Address2} onChange={(e) => onFormChange('Address2', e.target.value)} placeholder='Apt, Ste, Unit' /> <br />
              </label>
              <label>
                City <span className='required'>*</span><br />
                <input type="text" id="city" value={formProperties.City} onChange={(e) => onFormChange('City', e.target.value)} /> <br />
              </label>
              <label>
                State <span className='required'>*</span><br />
                <InputMask className="fake-txtbox" value={formProperties.State} onChange={(e) => onFormChange('State', e.target.value)} mask="aaaaa" maskChar=" " /> <br />
              </label>
              <label>
                Zip Code <span className='required'>*</span><br />
                <InputMask className="fake-txtbox" value={formProperties.Zip} onChange={(e) => onFormChange('Zip', e.target.value)} mask="99999-9999" maskChar=" " /> <br />
              </label>
              <label>
                Mobile Number (In case we need to reach you) <br />
                <InputMask placeholder='(123) 123-1234' className="fake-txtbox" value={formProperties.Phone1} onChange={(e) => onFormChange('Phone1', e.target.value)} mask="(999) 999-9999" maskChar=" " /> <br />
              </label>
            </div>

            <div>
              <label>
                Would you like the city to send you updates? <br />
                <input type="checkbox" id="city-updates" checked={formProperties.citySendUpdates} onChange={(e) => onFormChange('citySendUpdates', !formProperties.citySendUpdates)} /> <br />
              </label>
              <p><span className='bold-txt'>Payment Methods</span></p>
              {props.stripeUser.paymentMethods && props.stripeUser.paymentMethods.data && props.stripeUser.paymentMethods.data[0] ?
                <table className='payment-methods-table'>
                  <tr className='table-header'>
                    <th>Credit/Debit Cards</th>
                    <th>Expires</th>
                    <th></th>
                    <th></th>
                  </tr>
                  {props.stripeUser.paymentMethods.data.map(x => {
                    return <tr><td><b>{x.card.brand.toUpperCase()}</b> ending in <b>{x.card.last4}</b></td><td>{x.card.exp_month}/{x.card.exp_year}</td><td>{x.isDefault ? <p className='center'><b>Default</b></p> : <p onClick={() => setDefaultPaymentMethod(x.id)} className='center set-as-default'>Set as Default</p>}</td><td><p className='delete-btn center' onClick={() => showDeletionModal(x)}>Delete</p></td></tr>
                  })}
                </table> : ''}
              <br />
              {props.stripeUser.bankAccountsPM && props.stripeUser.bankAccountsPM.data && props.stripeUser.bankAccountsPM.data[0] ?
                <table className='payment-methods-table'>
                  <tr className='table-header'>
                    <th>Bank Accounts</th>
                    <th></th>
                    <th></th>
                  </tr>
                  {props.stripeUser.bankAccountsPM.data.map(x => {
                    return <tr><td><b>{x.us_bank_account.bank_name}</b> ending in <b>{x.us_bank_account.last4}</b></td><td>{x.isDefault ? <p className='center'><b>Default</b></p> : <p onClick={() => setDefaultPaymentMethod(x.id)} className='center set-as-default'>Set as Default</p>}</td><td><p className='delete-btn center' onClick={() => showDeletionModal(x)}>Delete</p></td></tr>
                  })}
                </table> : ''}
              <br />
              <p className='blue-text' onClick={addPaymentMethod}>+ Add Payment Method</p>
              <p>
                <span className='bold-txt'>Autopay</span><br />
                Using Autopay, you can have your water bill paid automatically using your default credit card or bank account. After you enable Autopay, funds are withdrawn automatically on the bill due date each month. You can enroll in Autopay at anytime.
              </p>
              <label className="switch">
                <input type="checkbox" checked={formProperties.autopay} onChange={(e) => onFormChange('autopay', !formProperties.autopay)} />
                <span className="slider round"></span>
              </label>
              <div className='two-trees'>
                <label>
                  <input type="checkbox" checked={formProperties.twoForTrees} onChange={(e) => onFormChange('twoForTrees', !formProperties.twoForTrees)} /> Would you like to donate $2 for 2 for Trees each billing cycle?<br />
                </label>
              </div>
              {errors && <p className='errors'>{errors}</p>}
              <input onClick={saveAccountChanges} className="blue-button" type="button" id="saveBtn" value="Save Account Changes" />
            </div>
          </div>
        }
      </div>
    </div >
  );
}

export default ManageAccount;