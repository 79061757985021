import React, { useState, useEffect } from "react";
import Navigation from './Navigation';

export function Help(props) {
  return (
    <div>
      <Navigation bettyUser={props.bettyUser} activeAccount={props.activeAccount} setActiveAccount={props.setActiveAccount} />
      <div className='page-content'>
        <embed src="https://beehivetilcache.blob.core.windows.net/staticassets/B2C/NE_Lincoln/PaymentPortal/pp-lincoln-help.html" width="100%" height="100%" />
      </div>
    </div>
  );
}

export default Help;