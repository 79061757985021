import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import CheckoutForm from './CheckoutForm';
import { Elements } from "@stripe/react-stripe-js";
import { queryHelpers } from "@testing-library/dom";
import * as helpers from './../helpers';

let init = false;

export function QuickPay(props) {
  const [myState, _setMyState] = React.useState(0);
  const myStateRef = React.useRef(myState);
  const setMyState = data => {
    myStateRef.current = data;
    _setMyState(data);
  }

  const { instance, accounts, inProgress } = useMsal();
  const [page, setPage] = useState(1);
  const [clientSecret, setClientSecret] = useState("");
  const [errors, setErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  //Page 1
  const [currentDue, setCurrentDue] = useState(0);
  const [pastDue, setPastDue] = useState(0);
  const [customerId, setCustomerId] = useState('');
  const [zip, setZip] = useState('');
  const [acctNum, setAcctNum] = useState('');

  //Page 2
  const [paymentRadio, setPaymentRadio] = useState('');
  const [customAmount, setCustomAmount] = useState(0);
  const [twoForTrees, setTwoForTrees] = useState(false);
  const [email, setEmail] = useState('');

  //Page 3
  const [amountPaying, setAmountPaying] = useState(null);

  let authenticate = () => {
    instance.loginRedirect();
  }

  let getBalancesOwed = () => {
    let errorBox = document.getElementById('error-box');

    setIsLoading(true);
    /* Return from database (current amount due, past amount due) given user's input of (Account number, zip code) */
    if (isNaN(zip)) {
      setErrors(<p><b>Invalid Zip Code:</b> Zip code must be entered with just numbers, ex: '12345' or '123451234'</p>);
      setIsLoading(false);
      errorBox.style.display = "flex";
    } else {
      errorBox.style.display = "none";
      helpers.api('GetQuickPayBalance', { zip: zip, acctNum: acctNum }, (result) => {
        setIsLoading(false);
        if (!result.AccountId) {
          errorBox.style.display = "flex";
          setErrors(<p><b>Invalid Credentials:</b> Please verify that you are entering the correct Account Number (which can be found on your bill) and the correct Zip Code.</p>);
        } else {
          errorBox.style.display = "none";
          setErrors('');
          setCurrentDue(result.CurrentDue);
          setPastDue(result.PastDue);
          setPage(2);
        }
      });
    }
  }

  let gotoPayment = () => {

    setIsLoading(true);
    let payment = 0;
    let errors = '';

    switch (paymentRadio) {
      case 'current':
        payment += currentDue;
        break;
      case 'past':
        payment += pastDue;
        break;
      case 'other':
        if (customAmount > 0) {
          payment += customAmount;
        } else {
          payment = 0;
        }
        break;
      default:
        errors += 'ERROR: Must select a payment amount. ';
        break;
    }

    if (twoForTrees) {
      payment += 2;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors += 'ERROR: Must submit a valid email. ';
    }

    if (payment < .50) {
      errors += 'ERROR: Must make a payment for more than $0.50';
    }

    setAmountPaying(payment);
    let errorBox = document.getElementById('error-box');

    if (errors) {
      errorBox.style.display = 'flex';
      setIsLoading(false);
      setErrors(<p>{errors}</p>);
    } else {
      errorBox.style.display = 'none';
      setErrors('');
      callStripe(payment.toFixed(2));
      setPage(3);
    }

  }

  let callStripe = (payment) => {
    helpers.api('PaymentIntent', { amount: payment, email: email, accountNumber: acctNum, twoForTrees: twoForTrees }, (result) => {
      setIsLoading(false);
      setClientSecret(result.clientSecret);
    });
  };

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
    loader: 'auto'
  };

  let keyup = (e) => {
    if (e.key === 'Enter') {
      if (page == 1) {
        getBalancesOwed();
      }
      if (page == 2) {
        gotoPayment();
      }
    }
  }

  useEffect(() => {
    if (!init) {
      init = true;
      document.addEventListener('keyup', keyup);
    }
    return () => {
      init = false;
      document.removeEventListener('keyup', keyup);
    }
  })

  return (
    <div className='flex'>
      {isLoading && <div id='loading-modal' className='modal'>
        <div className='loader'><div className='loader-white'></div><div className='loader-light'></div><div className='loader-ovl'></div></div>
      </div>}
      <div className='quick-pay-pic' />
      <div className='quick-content'>
        {(page === 1 || page === 2) ? <div className='flex-column'><h2 className='center'>Lincoln Water and Wastewater</h2><img className='city-logo' src='https://beehivetilcache.blob.core.windows.net/staticassets/B2C/NE_Lincoln/Logo.svg' /></div> : <div className='flex-row-page3'><img className='city-logo' height='75px' width='75px' src='https://beehivetilcache.blob.core.windows.net/staticassets/B2C/NE_Lincoln/Logo.svg' /><div className='paying-bill-header'>Paying Bill for <b>{helpers.formatToCurrency(amountPaying * 100)}</b></div></div>}        <div className='quick-content-form'>
          {(page === 1) &&
            <div className='quick-pay-form'>
              <h2>Quick Pay</h2>
              <div>
                <p className='bold-txt'>Account Number</p>
                <input onChange={(e) => { setAcctNum(e.target.value) }} type='text' id='acc-num' className='max-width qp-textbox' autoFocus /><br />
                <p className='bold-txt'>Zip Code of Service Address</p>
                <input onChange={(e) => { setZip(e.target.value) }} type='text' id='zip-code' className='max-width qp-textbox' />
              </div>
              <input onClick={getBalancesOwed} className="blue-button max-width" type="button" value="Continue" />
            </div>
          }
          {(page === 2) &&
            <div className='quick-pay-form'>
              <h3><span className='bold-txt'>Select your payment amount</span></h3>
              <div className="">
                <label>
                  <input onChange={(e) => { setPaymentRadio(e.target.value) }} checked={paymentRadio === 'current'} type="radio" value="current" name="amount" /> <span className='bold-txt'>{helpers.formatToCurrency(currentDue * 100)}</span> (current amount due)<br />
                </label>
                <label>
                  <input onChange={(e) => { setPaymentRadio(e.target.value) }} checked={paymentRadio === 'past'} type="radio" value="past" name="amount" /> <span className='bold-txt'>{helpers.formatToCurrency(pastDue * 100)}</span> (past due amount)<br />
                </label>
                <div className='otherAmountEntry'>
                  <label>
                    <input onChange={(e) => { setPaymentRadio(e.target.value); document.getElementById('customAmount').focus(); }} checked={paymentRadio === 'other'} type="radio" value="other" name="amount" /> Other Amount <br />
                  </label>
                  <label>
                    <input onChange={(e) => { setCustomAmount(parseFloat(e.target.value)) }} id="customAmount" type="number" placeholder='$0.00' min='0' /><br />
                  </label>
                </div>
                <label>
                  <input onChange={(e) => { setTwoForTrees(!twoForTrees) }} type="checkbox" /> Include $2 towards 2 for Trees<br />
                </label>
                <div className='emailEntry'>
                  Email (for your receipt):
                  <input onChange={(e) => { setEmail(e.target.value) }} type="text" placeholder="youremail@domain.com" /><br />
                </div>
              </div>
              <input onClick={gotoPayment} className="blue-button max-width" type="button" value="Pay Bill" />
            </div>
          }
          {(page === 3) && clientSecret &&
            <div className=''>
              <Elements options={options} stripe={props.stripePromise} className='stripe'>
                <CheckoutForm /*amountPaying={amountPaying}*/ clientSecret={clientSecret} />
              </Elements>
            </div>
          }
        </div>
        <div id='error-box'>
          <svg className='error-svg' xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" role="img" aria-labelledby="dangerIconTitle" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" color="#000000"> <title id="dangerIconTitle">Danger</title> <path d="M12 10L12 13" /> <line x1="12" y1="16" x2="12" y2="16" /> <path d="M12.8619342,4.46528817 L21.1135176,18.4929799 C21.3935371,18.969013 21.2346366,19.5819147 20.7586035,19.8619342 C20.6049268,19.9523322 20.4298761,20 20.2515834,20 L3.74841664,20 C3.19613189,20 2.74841664,19.5522847 2.74841664,19 C2.74841664,18.8217072 2.7960844,18.6466565 2.88648243,18.4929799 L11.1380658,4.46528817 C11.4180853,3.98925504 12.030987,3.83035459 12.5070201,4.11037408 C12.6535738,4.19658212 12.7757262,4.3187345 12.8619342,4.46528817 Z" /></svg>
          {errors}
        </div>
        <div className='center pad-bot'>
          Need to login or sign up to manage paperless billing or Autopay? <br /><span onClick={authenticate} className='blue-text'>Click Here</span>
        </div>
      </div>
    </div>
  );
}

export default QuickPay;