import React, { useState, useEffect } from "react";
import Navigation from './Navigation';
import * as helpers from '../helpers';
import { Elements } from "@stripe/react-stripe-js";
import AddNewPayment from './AddNewPayment';
import { Link } from "react-router-dom";

export function StatementSummaryComplete(props) {

  let params = new URLSearchParams(window.location.search);
  let amount = params.get('amount');
  let success = params.get('success');

  return (
    <div onClick={props.getUserData}>
      <Navigation bettyUser={props.bettyUser} activeAccount={props.activeAccount} setActiveAccount={props.setActiveAccount} />
      {success === 'yes' ?
        <div>
          <div className='page-content'>
            <div>
              <div>
                <div className='flex-row'>
                  <svg className='margin-right' xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 48 48" version="1" enable-background="new 0 0 48 48">
                    <polygon fill="#43A047" points="40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9" />
                  </svg>
                  <h1>Payment Successful</h1>
                </div>
                <span className='bold-txt'>Payment Amount: </span> {helpers.formatToCurrency(amount * 100)}<br />
              </div>
              <div>
                <p>It may take up to 20 minutes for your payment to process.</p>
                <p>We have sent you an email receipt for your records.</p>
              </div>
            </div>
            <Link to="statement-summary"><input className="blue-button" type="button" value="Return to Statement Summary" /></Link>
          </div>
        </div> : success === 'processing' ?
          <div>
            <div className='page-content'>
              <div>
                <div>
                  <div className='flex-row'>
                    <svg className='margin-right' xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 48 48" version="1" enable-background="new 0 0 48 48">
                      <polygon fill="#43A047" points="40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9" />
                    </svg>
                    <h1>Payment Submitted and is Processing</h1>
                  </div>
                  <span className='bold-txt'>Payment Amount: </span> {helpers.formatToCurrency(amount * 100)}<br />
                </div>
                <div>
                  <p>It may take up to 20 minutes for your payment to process.</p>
                  <p>We have sent you an email recept for your records.</p>
                </div>
              </div>
              <Link to="statement-summary"><input className="blue-button" type="button" value="Return to Statement Summary" /></Link>
            </div>
          </div> :
          <div>
            <div className='page-content'>
              <div>
                <div>
                  <div className='flex-row'>
                    <svg className='margin-right' xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" role="img" aria-labelledby="dangerIconTitle" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" color="#000000"> <title id="dangerIconTitle">Danger</title> <path d="M12 10L12 13" /> <line x1="12" y1="16" x2="12" y2="16" /> <path d="M12.8619342,4.46528817 L21.1135176,18.4929799 C21.3935371,18.969013 21.2346366,19.5819147 20.7586035,19.8619342 C20.6049268,19.9523322 20.4298761,20 20.2515834,20 L3.74841664,20 C3.19613189,20 2.74841664,19.5522847 2.74841664,19 C2.74841664,18.8217072 2.7960844,18.6466565 2.88648243,18.4929799 L11.1380658,4.46528817 C11.4180853,3.98925504 12.030987,3.83035459 12.5070201,4.11037408 C12.6535738,4.19658212 12.7757262,4.3187345 12.8619342,4.46528817 Z" />
                    </svg>
                    <h1>Payment Failed</h1>
                  </div>
                  <p className='errors-stripe'>There was an error processing your payment.</p>
                </div>
                <div>
                  <p>Please return to the statement summary and try again.</p>
                </div>
              </div>
              <Link to="statement-summary"><input className="blue-button" type="button" value="Return to Statement Summary" /></Link>
            </div>
          </div>}
    </div>
  );
}

export default StatementSummaryComplete;