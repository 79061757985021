import './App.css';
import './index.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import StatementSummary from './Scenes/StatementSummary';
import Help from './Scenes/Help';
import ManageAccount from './Scenes/ManageAccount';
import QuickPay from './Scenes/QuickPay';
import BillHistory from './Scenes/BillHistory';

import React, { useState, useEffect } from "react";
import CheckoutForm from './Scenes/CheckoutForm';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import QuickPaySubmitted from './Scenes/QuickPaySubmitted';
import * as helpers from './helpers';
import { useMsal } from '@azure/msal-react';
import StatementSummaryComplete from './Scenes/StatementSummaryComplete';
import { b2cPolicies } from './authConfig';

const stripePromise = loadStripe("pk_live_51JflEYCG5ttGVn1rL0PYtG5BhRkOZC7qykZqnoSi94Rdo73T2Q1hH0xSqKBK0HPCwAO5JisvM6KqL4YZIHSR3EMR00kAODzw0N", {
  betas: ['us_bank_account_beta_2']
});

function App(props) {

  const [customerId, setCustomerId] = useState('');
  const [bettyUser, setBettyUser] = useState('');
  const [stripeUser, setStripeUser] = useState('');
  const [init, setInit] = useState(false);

  const [activeAccount, setActiveAccount] = useState('');

  const { instance, accounts, inProgress } = useMsal();

  if (!init && props.loggedIn) {
    if (!window.getKey) {
      window.getKey = (callback) => {
        let curAct = instance.getAccountByHomeId(accounts[0].homeAccountId);
        let request = {
          scopes: [],
          account: curAct
        }

        instance.acquireTokenSilent(request).then((result) => {
          callback(result.idToken);
        }).catch((err) => {
          console.log("get key silent failed: ");
          instance.logout({ account: curAct });
          instance.loginRedirect({ authority: b2cPolicies.authorities.signUpSignIn.authority });
        })
      }
    }
  }

  let getUserData = () => {
    let thisCustomerId = accounts[0].idTokenClaims.extension_CustomerId;
    helpers.apiAuth('GetAccount', {}, (result) => {
      setBettyUser({ user: result });
      setActiveAccount(result.Accounts[0]);
    });
    helpers.apiAuth('GetStripeCustomerData', { customerId: thisCustomerId }, (result) => {
      setStripeUser({ customer: result.customer, paymentMethods: result.paymentMethods, bankAccountsPM: result.bankAccountsPM });
    });
  }
  let initialPage = props.loggedIn ? 'statement-summary' : 'quickpay';
  if (window.location.pathname === '/quickpaysubmitted') {
    initialPage = 'quickpaysubmitted';
  }
  if (window.location.pathname === '/quickpaysubmitted?failed') {
    initialPage = 'quickpaysubmitted?failed';
  }

  //URL Parameters
  let params = new URLSearchParams(window.location.search);
  let amount = params.get('amount');
  let failed = params.get('failed');

  useEffect(() => {
    if (!init && window.getKey) {
      setInit(true);
      setCustomerId(accounts[0].idTokenClaims.extension_CustomerId);
      getUserData();
    }
  })

  return (
    <div className="App">
      <Router>
        <Redirect exact from="/" to={initialPage} />
        <Switch>
          <Route path="/quickpaysubmitted" component={() => <QuickPaySubmitted authentication={props.authentication} amount={amount} failed={failed} />} />
          <Route path="/statement-summary" component={() => <StatementSummary authentication={props.authentication} stripePromise={stripePromise} bettyUser={bettyUser} stripeUser={stripeUser} getUserData={getUserData} activeAccount={activeAccount} setActiveAccount={setActiveAccount} customerId={customerId}/*setBettyUser={setBettyUser} setStripeUser={setStripeUser}*/ />} />
          <Route path="/account" component={() => <ManageAccount authentication={props.authentication} stripePromise={stripePromise} bettyUser={bettyUser} stripeUser={stripeUser} getUserData={getUserData} activeAccount={activeAccount} setActiveAccount={setActiveAccount} setBettyUser={setBettyUser} customerId={customerId} setStripeUser={setStripeUser} />} />
          <Route path="/help" component={() => <Help authentication={props.authentication} bettyUser={bettyUser} activeAccount={activeAccount} setActiveAccount={setActiveAccount} />} />
          <Route path="/statement-summary-complete" component={() => <StatementSummaryComplete activeAccount={activeAccount} setActiveAccount={setActiveAccount} bettyUser={bettyUser} amount={amount} getUserData={getUserData} />} />
          <Route path="/quickpay" component={() => <QuickPay authentication={props.authentication} stripePromise={stripePromise} />} />
          <Route path="/bill-history" component={() => <BillHistory authentication={props.authentication} bettyUser={bettyUser} activeAccount={activeAccount} setActiveAccount={setActiveAccount} />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;