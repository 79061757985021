import React, { useEffect, useState } from "react";
import './../Stripe.css'
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
// import { useHistory } from "react-router-dom";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  // const history = useHistory();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [return_url, setReturnUrl] = useState('https://waterbill.lincoln.ne.gov/quickpaysubmitted?failed');

  let [isCardPay, setIsCardPay] = useState(true);
  let [fullName, setFullName] = useState('');
  let [email, setEmail] = useState('');

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = props.clientSecret;

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setReturnUrl("https://waterbill.lincoln.ne.gov/quickpaysubmitted?amount=" + paymentIntent.amount);
      if (paymentIntent.status === 'failed') {
        setReturnUrl("https://waterbill.lincoln.ne.gov/quickpaysubmitted?failed=true");
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    let { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: return_url,
      },
    });

    // if (error && true) {
    //   error = await stripe.collectUsBankAccountForPayment(props.clientSecret, {
    //     billing_details: {
    //       name: fullName,
    //       email: email
    //     }
    //   })
    //     .then(({ payIntent, error }) => {
    //       if (error) {
    //         console.log(error);
    //       } else {
    //         stripe.confirmUsBankAccountPayment(props.clientSecret).then(({ paymentIntent, error }) => {
    //           if (error) {
    //             console.log(error);
    //             setMessage(error.message);
    //           } else {
    //             if (paymentIntent.status === 'succeeded' || paymentIntent.status === 'processing') {
    //               history.push('/quickpaysubmitted?failed=false&amount=' + paymentIntent.amount);
    //             } else {
    //               setMessage('You will be sent an email to confirm the microdeposits with a link to complete your payment. Expect a $0.01 deposit to the account provided in 1–2 business days and an email with additional instructions to verify your bank account.')
    //             }
    //           }
    //         })
    //       }
    //     });
    // }

    if (error && (error.type === "card_error" || error.type === "validation_error")) {
      setMessage(error.message);
    } else if (error) {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" className={!isCardPay && 'display-none'} />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {message && <div className='errors-stripe' id="payment-message">{message}</div>}
    </form>
  );
}